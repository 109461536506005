<template>

<div class="Que" >
<router-view v-show="showAddSch" @showSchInfo="showSch"></router-view>
    <div class="addSch" v-show="!showAddSch">
        <div class="addButton" @click="addSch">
             <router-link to="/user/MSch/addSchArt">
                 <el-button type="primary">发布<i class="el-icon-circle-plus-outline"></i></el-button>
            </router-link>
        </div>
        <el-tabs class="el-tabs" v-model="activeName" @tab-click="handleClick">
            <!-- 全部列表 -->
        <el-tab-pane label="全部" name="first">
        <div v-show="isShow4" >
            <div class="queInfo"

             v-for="(item,index) in questions" :key="index">
            <div class="top">
            <div class="title">
                <h3>{{item.title}} <span v-show="item.isdown===0?false:true">已下架</span></h3>
                <!-- <p>￥<span>{{item.price}}</span></p> -->
            </div>
            <div class="data">
                <p><span>发表时间:</span>{{item.pubdata | dataFormat}}</p>
            </div>
        </div>
        <!-- <div class="content">
            <p v-html="item.content"></p>
        </div> -->
        <!-- <div class="ql-snow">
          <div class="ql-editor">
            <div class="content">
              <div v-html="item.content"></div>
          </div>
          </div>
      </div> -->
        <div class="info">
            <div class="views">
                <ul>
                     <li><i class="view-icon"></i><span>{{item.view}}</span></li>

                </ul>
            </div>
            <div class="edit">
                <ul>
                  <li @click.stop="reEditSchArt(item.id)">
                    <router-link :to="{ name: 'editSchArtRoute', params: { schArtId: item.id }}">
                      <span><i class="el-icon-edit"></i></span>重新编辑
                    </router-link>
                  </li>
                    <li @click.stop="$router.push('/schArt/'+item.id,{params:{schArtId:item.id}})">查看</li>

                    <li @click.stop="delQuestion(item.id)"><span><i class="el-icon-delete"></i></span>删除</li>

                </ul>

            </div>
        </div>
        </div>
    </div>

    <el-empty class="el-empty" v-show="!isShow4" :image-size="200"></el-empty>
    </el-tab-pane>

     <!-- 已发布列表 -->
        <el-tab-pane label="发布中" name="second">
    <div v-show="isShow1" >
            <div class="queInfo"
            @click="$router.push('/schArt/'+item.id,{params:{schArtId:item.id}})"
              v-for="(item,index) in pubArr" :key="index">
            <div class="top">
            <div class="title">
                <h3>{{item.title}} <span v-show="item.isdown===0?false:true">已下架</span></h3>
                <!-- <p>￥<span>{{item.price}}</span></p> -->
            </div>
            <div class="data">
                <p><span>发表时间:</span>{{item.pubdata | dataFormat}}</p>
            </div>
        </div>
        <!-- <div class="content">
             <p v-html="item.content"></p>
        </div> -->
        <div class="ql-snow">
          <div class="ql-editor">
            <div class="content">
              <div v-html="item.content"></div>
          </div>
          </div>
      </div>
        <div class="info">
            <div class="views">
                <ul>
                     <li><i class="view-icon"></i><span>{{item.view}}</span></li>

                </ul>
            </div>
            <div class="edit">
                <ul>
                  <li @click.stop="reEditSchArt(item.id)">
                    <router-link :to="{ name: 'editSchArtRoute', params: { schArtId: item.id }}">
                      <span><i class="el-icon-edit"></i></span>重新编辑
                    </router-link>
                  </li>
                    <li @click.stop="$router.push('/schArt/'+item.id,{params:{schArtId:item.id}})">查看</li>
                    <li @click.stop="downQue(item.id)">下架</li>
                    <li @click.stop="delQuestion(item.id)"><span><i class="el-icon-delete"></i></span>删除</li>

                </ul>

            </div>
        </div>
        </div>
    </div>
    <el-empty class="el-empty" v-show="!isShow1" :image-size="200"></el-empty>
    </el-tab-pane>

      <!-- 下架列表 -->
    <el-tab-pane label="下架" name="third">
            <div v-show="isShow2" >
            <div class="queInfo"
            @click="$router.push('/schArt/'+item.id,{params:{schArtId:item.id}})"
            v-for="(item,index) in downArr" :key="index">
            <div class="top">
            <div class="title">
                <h3>{{item.title}} <span v-show="item.isdown===0?false:true">已下架</span></h3>
                <!-- <p>￥<span>{{item.price}}</span></p> -->
            </div>
            <div class="data">
                <p><span>发表时间:</span>{{item.pubdata | dataFormat}}</p>
            </div>
        </div>
        <!-- <div class="content">
            <p v-html="item.content"></p>
        </div> -->
        <div class="ql-snow">
          <div class="ql-editor">
            <div class="content">
              <div v-html="item.content"></div>
          </div>
          </div>
      </div>
        <div class="info">
            <div class="views">
                <ul>
                     <li><i class="view-icon"></i><span>{{item.view}}</span></li>

                </ul>
            </div>
            <div class="edit">
                <ul>
                  <li @click.stop="reEditSchArt(item.id)">
                    <router-link :to="{ name: 'editSchArtRoute', params: { schArtId: item.id }}">
                      <span><i class="el-icon-edit"></i></span>重新编辑
                    </router-link>
                  </li>
                     <li @click.stop="$router.push('/schArt/'+item.id,{params:{schArtId:item.id}})">查看</li>
                     <li @click.prevent.stop="backDownQue(item.id)">恢复</li>
                </ul>
            </div>
        </div>
        </div>
    </div>
     <el-empty class="el-empty" v-show="!isShow2" :image-size="200"></el-empty>
            </el-tab-pane>
    <!-- 回收站 -->

        <el-tab-pane label="回收站" name="fourth">

            <div v-show="isShow3" >
            <div class="queInfo"  v-for="(item,index) in delArr" :key="index">
            <div class="top">
            <div class="title">
                <h3>{{item.title}} <span v-show="item.isdown===0?false:true">已下架</span></h3>
                <!-- <p>￥<span>{{item.price}}</span></p> -->
            </div>
            <div class="data">
                <p><span>发表时间:</span>{{item.pubdata | dataFormat}}</p>
            </div>
        </div>
        <!-- <div class="content">
             <p v-html="item.content"></p>
        </div> -->
        <div class="ql-snow">
          <div class="ql-editor">
            <div class="content">
              <div v-html="item.content"></div>
          </div>
          </div>
      </div>
        <div class="info">
            <div class="views">
                <ul>

                     <li><i class="view-icon"></i><span>{{item.view}}</span></li>

                </ul>
            </div>
            <div class="edit">
                <ul>
                  <li @click.stop="reEditSchArt(item.id)">
                    <router-link :to="{ name: 'editSchArtRoute', params: { schArtId: item.id }}">
                      <span><i class="el-icon-edit"></i></span>重新编辑
                    </router-link>
                  </li>
                     <li @click.stop="recoveyQue(item.id)">恢复</li>
                    <li @click.stop="rDelQuestion (item.id)"><span><i class="el-icon-delete"></i></span>删除</li>

                </ul>

            </div>
        </div>
        </div>
    </div>
     <el-empty class="el-empty" v-show="!isShow3" :image-size="200"></el-empty>
            </el-tab-pane>

  </el-tabs>
    </div>

</div>
</template>

<script>
import dayjs from 'dayjs'
import { getSchArt, downSchArt, backDownSchArt, delSchArt, backDelSchArt, rdelSchArt } from '../../API/schArt.js'
export default {
  name: 'userQue',
  data () {
    return {
      // 全部列表
      questions: [],
      // 已发布问题列表
      pubArr: [],
      // 下架的问题列表
      downArr: [],
      // 回收站
      delArr: [],
      showAddSch: false,
      isShow1: true,
      isShow2: true,
      isShow3: true,
      isShow4: true,
      activeName: 'first'
    }
  },
  methods: {
    //   tab栏
    handleClick (tab, event) {

    },
    showDown () {

    },
    // 发布文章
    addSch () {
      this.showAddSch = true
    },
    reEditSchArt (id){
      this.showAddSch = true
    },
    showSch (data) {
      this.showAddSch = false
    },
    // 下架文章
    async downQue (id) {
      const { data: res } = await downSchArt({ id: id })

      if (res.status === 0) {
        this.pubArr.map((item, index, arr) => {
          if (id === item.id) {
            this.downArr = [...this.downArr, item]
            this.pubArr.splice(index, 1)
            this.$message({
              showClose: true,
              type: 'success',
              message: res.message
            })
            window.location.reload(true);
          }
        })
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.message
        })
      }
    },

    // 恢复下架文章
    async backDownQue (id) {
      const { data: res } = await backDownSchArt({ id: id })

      if (res.status === 0) {
        this.downArr.map((item, index, arr) => {
          if (id === item.id) {
            // 下架列表删除该内容
            this.downArr.splice(index, 1)
            // 已发布列表添加该内容
            this.pubArr = [...this.pubArr, item]
            this.$message({
              showClose: true,
              type: 'success',
              message: res.message
            })
            window.location.reload(true);
          }
        })
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.message
        })
      }
    },

    //   假删除文章
    // eslint-disable-next-line vue/no-dupe-keys
    async delQuestion (id) {
      const { data: res } = await delSchArt({ id: id })
      if (res.status === 0) {
        this.questions.map((item, index, arr) => {
          if (id === item.id) {
            //   全部列表删除当前元素
            this.questions.splice(index, 1)
            this.pubArr.splice(index, 1)
            // 删除列表增添当前元素
            this.delArr = [...this.delArr, item]
            this.$message({
              showClose: true,
              type: 'success',
              message: res.message
            })
            window.location.reload(true);
          }
        })
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.message
        })
      }
    },
    // 恢复删除的文章
    async recoveyQue (id) {
      const { data: res } = await backDelSchArt({ id: id })
      if (res.status === 0) {
        this.delArr.map((item, index, arr) => {
          if (id === item.id) {
            this.delArr.splice(index, 1)
            this.pubArr = [...this.pubArr, item]
            this.questions = [...this.questions, item]
            this.$message({
              showClose: true,
              type: 'success',
              message: res.message
            })
            window.location.reload(true);
          }
        })
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.message
        })
      }
    },
    open () {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    // 真正删除
    rDelQuestion (id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await rdelSchArt({ id: id })
        if (res.status === 0) {
          this.delArr.map((item, index) => {
            if (id === item.id) {
              this.delArr.splice(index, 1)
            }
          })
        }
        this.$message({
          showClose: true,
          type: 'success',
          message: '删除成功!'
        })
        window.location.reload(true);
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 获取所有文章
    async getQuestions () {
      const { data: res } = await getSchArt()

      if (res.status === 0) {
        res.data.map((item, index, arr) => {
          // 已发布列表(包含既未下架也未删除)
          if (item.isdelete === 0 && item.isdown === 0) {
            this.pubArr = [...this.pubArr, item]
          }
          //   全部列表(不包括删除的)
          if (item.isdelete === 0) {
            this.questions = [...this.questions, item]
          }
          //   下架列表
          if (item.isdown === 1 && item.isdelete === 0) {
            this.downArr = [...this.downArr, item]
          }
          //   删除列表（包括下架或删除的）
          if (item.isdelete === 1) {
            this.delArr = [...this.delArr, item]
          }
        })
      }

      if (this.pubArr.length !== 0) {
        this.isShow1 = true
      } else {
        this.isShow1 = false
      }
      if (this.downArr.length !== 0) {
        this.isShow2 = true
      } else {
        this.isShow2 = false
      }
      if (this.delArr.length !== 0) {
        this.isShow3 = true
      } else {
        this.isShow3 = false
      }
      if (this.questions.length !== 0) {
        this.isShow4 = true
      } else {
        this.isShow4 = false
      }
    }
    // 下架文章
    // downQuestion () {

    // }

  },
  created () {
    this.getQuestions()
  },
  filters: {
    dataFormat: (day) => {
      return dayjs(day).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style lang='less' scoped>
*{
    margin: 0;
    padding: 0;
}
.Que{
  margin: .5rem auto;
    width:70%;
li{
    list-style: none;
}
.addSch{

        background-color: #fff;
        position: relative;
        .addButton{
            position:absolute;
            top: 55px;
              right:20px;
              cursor: pointer;
              z-index: 100;
            button{

                padding: 11px 10px;
                i{
                    display: inline-block;
                    padding-left:3px;
                }
            }
        }
        .el-tabs{
            padding: 10px 15px;
        }
}
.queInfo{
    overflow-x: hidden;
    background-color:#fff ;
    border-bottom: 1px solid #dbdbdf;

    .top{
        display: flex;
        justify-content: space-between;
        padding: 20px 25px;
        width:90%;
        .title{
            display: flex;
            align-items: center;
            p{
                margin: 0 20px;
                color: #FF3535;
                font-size: 24px;
                span{

                }
            }
            h3{
                span{
                    color: #d47171;
                }
            }
        }
        .data{
            min-width: 142px;
            text-align: right;
            height: 24px;
            line-height: 24px;
        }
    }
    .content{
         padding: 15px 25px;
         height: 60px;
        //  overflow: hidden;
         text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
         p{
          // width: 430px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-height: 30px;
          -webkit-box-orient: vertical;
          text-indent: 12px;
         }
    }
    .info{
        display: flex;
        padding: 15px 20px;
        justify-content: space-between;
        .views{
            ul{
                display: flex;
                 li{
                    margin: 0 5px;
                     i{
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        vertical-align: bottom;
                        margin-right: 8px;
                        background-size: 16px!important;
                    }
                    .view-icon{
                        background: url('../../../public/images/浏览.png') no-repeat;
                    }
                    .like-icon{
                        background: url('../../../public/images/点赞.png');
                    }
                    .comment-icon{
                        background: url('../../../public/images/消息.png');
                    }
                }
            }
        }
        .edit{
            ul{
                display: flex;
                li{
                    margin: 0 5px;
                    cursor: pointer;
                }
               li:hover{

                   color: #ff3535;
               }
            }
        }
    }
}
.footer{
  position: absolute;
  bottom: 0;
}
 .el-empty{
            background-color: #fff;
            padding: 8px 0;
    }
  }
</style>
